/**
 * Theme for OSW US site
 */
import type { Theme } from '@emotion/react';
import { buildTheme, globals } from 'common-ui';
import merge from 'lodash/merge';
import { icons } from 'common-font-icons';
import type { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types';
import { buildThemeMixinsUsLW, buildThemeVarsUsLW, buildThemeWidgetStylesUsLW } from 'dw-us-law/theme';
import { nHaasGroteskTxProFont, neueAachenProRegularFont, nHaasGroteskTxProFontRegular } from '../common/nextFont';

export const buildThemeVarsUSOsw = (vars: Pick<Theme, CommonThemeVars>) => {
  //primary
  const omahaRed100 = '#F3E6E8'; //color1
  const omahaRed500 = '#890419'; //color4
  const omahaRed700 = '#600312'; //color5
  const omahaRed900 = '#890419'; //color6
  //secondary
  const omahaCream200 = '#F8F5EF'; //color7
  const omahaCream400 = '#FAF3E6'; //color8
  const omahaWhite = '#FFFFFF'; //color9
  const omahaGrey100 = '#F8F8F8'; //color10
  const omahaGrey200 = '#EDEDED'; //color11
  const omahaGrey400 = '#BCBCBC'; //color12
  const omahaGrey600 = '#767676'; //color13
  const omahaGrey700 = '#616161'; //color14
  const omahaGrey800 = '#494949'; //color15
  const omahaBlack = '#222222'; //color16
  const omahaSvgCheckboxBg = '%23890419';
  //accent - Blues
  const omahaBlue100 = '#E8EEF1'; //color17
  const omahaBlue300 = '#9FB7C7'; //color18
  const omahaBlue400 = '#3E6E8E'; //color19
  const omahaBlue500 = '#165077'; //color20
  const omahaBlue700 = '#0F3853'; //color21
  const omahaBlue900 = '#0A2638'; //color22
  //accent - Reds
  const omahaFirehouseRed = '#CC2127'; //color23
  const omahaMaroon = '#830B2C'; //color24
  const omahaBrown = '#5F3738'; //color25
  //accent - Periwinkle
  const omahaPeriwinkle = '#AFB5C9'; //color26
  //accent - Orange
  const omahaLightOrange = '#F6CBAF'; //color27
  const omahaOrange = '#E06729'; //color28
  const omahaDarkOrange = '#C45629'; //color29
  //accent - Yellow
  const omahaLightYellow = '#FAE4B8'; //color30
  const omahaYellow = '#F1B300'; //color31
  const omahaDarkYellow = '#C78127'; //color32
  //accent - Teal
  const omahaLightBlue = '#B7CACE'; //color33
  const omahaTeal = '#00797F'; //color34
  const omahaDarkTeal = '#294F5A'; //color35
  const omahaAegean = '#2C3C4C'; //color36
  const omahaBlue600 = '#0055A0';

  //CTA colors
  const omahaCTABlack = omahaBlack;
  const omahaCTARolloverBlack = omahaRed700;
  const omahaCTARed = omahaRed500;

  const neueAachenPro = neueAachenProRegularFont.style.fontFamily;
  const neueHaasGroteskTextPro = nHaasGroteskTxProFont.style.fontFamily;
  const neueHaasGroteskTextProRegular = nHaasGroteskTxProFontRegular.style.fontFamily;

  const lwThemeVars = buildThemeVarsUsLW(vars);
  return merge({}, lwThemeVars, {
    meta: {
      name: 'OSW US',
    },
    icons,
    assets: {
      headerLogoImgUrl: `${globals.assetsPath}/next/images/brand-logo.svg`,
      headerLogoMobileImgUrl: `${globals.assetsPath}/next/images/brand-logo-mobile.svg`,
      footerLogoImgUrl: `${globals.assetsPath}/next/images/brand-logo-dark.svg`,
    },
    colors: {
      primary1: omahaRed500,
      primary2: omahaCream200,
      primary3: omahaCream200,
      primary4: omahaRed100,
      primary5: omahaRed700,
      primary6: omahaRed900,
      secondary1: omahaCream200,
      secondary2: omahaCream400,
      secondary3: omahaWhite,
      secondary4: omahaGrey100,
      secondary5: omahaGrey200,
      secondary6: omahaGrey400,
      secondary7: omahaGrey600,
      secondary8: omahaGrey700,
      secondary9: omahaGrey800,
      secondary10: omahaPeriwinkle,
      accentBlue100: omahaBlue100,
      accentBlue300: omahaBlue300,
      accentBlue400: omahaBlue400,
      accentBlue500: omahaBlue500,
      accentBlue600: omahaBlue600,
      accentBlue700: omahaBlue700,
      accentBlue900: omahaBlue900,
      accentFirehouseRed: omahaFirehouseRed,
      accentMaroon: omahaMaroon,
      accentBrown: omahaBrown,
      accentPeriwinkle: omahaPeriwinkle,
      accentLightOrange: omahaLightOrange,
      accentOrange: omahaOrange,
      accentDarkOrange: omahaDarkOrange,
      accentLightYellow: omahaLightYellow,
      accentYellow: omahaYellow,
      accentDarkYellow: omahaDarkYellow,
      accentLightBlue: omahaLightBlue,
      accentTeal: omahaTeal,
      accentDarkTeal: omahaDarkTeal,
      accentAegean: omahaAegean,
      radioBtnsPrimaryBg: omahaCTARed,
      cardBackground: omahaBlue500,

      //specific colour definitions
      btnsPrimaryBg: omahaCTARed,
      btnsPrimaryColor: vars.colors.white,
      btnsPrimaryHoverBg: omahaCTARolloverBlack,
      btnsPrimaryHoverColor: vars.colors.white,
      btnsSecondaryBg: vars.colors.white,
      btnsSecondaryColor: vars.colors.black,
      btnsSecondaryHoverBg: omahaRed700,
      btnsSecondaryHoverColor: vars.colors.white,
      btnsTertiaryBg: vars.colors.black,
      btnsTertiaryColor: vars.colors.white,
      btnsTertiaryBorder: vars.colors.white,
      btnsTertiaryHoverBg: omahaCTABlack,
      btnsTertiaryHoverColor: vars.colors.white,
      svgCheckboxBg: omahaSvgCheckboxBg,

      linkPrimaryColor: vars.colors.black,
      linkSecondaryColor: omahaCTABlack,
      linkTertiaryColor: omahaCTARed,

      headerCartBtn: omahaCTABlack,
      headerCartBtnSmall: omahaCTABlack,
      headerSignInBtnSmall: omahaCTARed,

      textPrimaryBg: omahaRed900,

      bgColors: {
        omahaGrey: omahaGrey400,
        omahaWhite: omahaWhite,
        omahaCream: omahaCream200,
        omahaRed: omahaRed500,
      },
    },
    fonts: {
      familyPrimary: neueAachenPro,
      familySecondary: lwThemeVars.fonts.roboto,
      familyTertiary: neueHaasGroteskTextPro,
      familyQuaternary: neueHaasGroteskTextProRegular,
      headerMainNavTitle: neueHaasGroteskTextPro,
      headerMainNavSubTitle: neueHaasGroteskTextPro,
      fontWeightRegular: '400',
      weightSmall: '400',
      weightMediumPlus: '600',
      weightBold: '700',
      fontWeightBold: '700',
    },
  });
};
export const buildThemeMixinsUSOsw = (vars: Omit<Theme, 'mixins' | 'widgets'>) => {
  const buildThemeWidget = buildThemeMixinsUsLW(vars);

  return merge({}, buildThemeWidget, {
    hxl: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize48};
      font-weight: ${vars.fonts.fontWeightRegular};
      line-height: ${vars.fonts.fontLineHeight90};
      letter-spacing: ${vars.fonts.fontLetterSpacing1};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize64};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight90};
      }
    `,
    h1: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize42};
      font-weight: ${vars.fonts.fontWeightRegular};
      line-height: ${vars.fonts.fontLineHeight95};
      letter-spacing: ${vars.fonts.fontLetterSpacing1};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize48};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight95};
      }
    `,
    h2: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize36};
      font-weight: ${vars.fonts.fontWeightRegular};
      line-height: ${vars.fonts.fontLineHeight100};
      letter-spacing: ${vars.fonts.fontLetterSpacing1};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize40};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight100};
      }
    `,
    h3: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize30};
      font-weight: ${vars.fonts.fontWeightRegular};
      line-height: ${vars.fonts.fontLineHeight105};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize36};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight105};
      }
    `,
    h4: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize24};
      font-weight: ${vars.fonts.fontWeightBold};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize32};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
    h5: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize22};
      font-weight: ${vars.fonts.fontWeightBold};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize32};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
    h6: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize20};
      font-weight: ${vars.fonts.fontWeightBold};
      line-height: ${vars.fonts.fontLineHeight125};
      letter-spacing: ${vars.fonts.fontLetterSpacing6};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize26};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight125};
      }
    `,
    h7: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize18};
      font-weight: ${vars.fonts.fontWeightBold};
      line-height: ${vars.fonts.fontLineHeight125};
      letter-spacing: ${vars.fonts.fontLetterSpacing6};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize22};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight125};
      }
    `,
    h8: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize16};
      font-weight: ${vars.fonts.fontWeightBold};
      line-height: ${vars.fonts.fontLineHeight125};
      letter-spacing: ${vars.fonts.fontLetterSpacing6};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize18};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight125};
      }
    `,
    eyebrowRegular: `
      font-family: ${vars.fonts.familyTertiary};
      font-size: ${vars.fonts.fontSize12};
      font-weight: ${vars.fonts.fontWeightSemiBold};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing7};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize14};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    eyebrowSmall: `
      font-family: ${vars.fonts.familyTertiary};
      font-size: ${vars.fonts.fontSize10};
      font-weight: ${vars.fonts.fontWeightSemiBold};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing7};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize12};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    utility: `
      font-family: ${vars.fonts.familyTertiary};
      font-size: ${vars.fonts.fontSize10};
      font-weight: ${vars.fonts.fontWeightSemiBold};
      line-height: ${vars.fonts.fontLineHeight160};
      letter-spacing: ${vars.fonts.fontLetterSpacing9};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize12};
        line-height: ${vars.fonts.fontLineHeight115};
        letter-spacing: ${vars.fonts.fontLetterSpacing8};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight160};
      }
    `,
    quote: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize20};
      font-style: ${vars.fonts.styleItalic};
      line-height: ${vars.fonts.fontLineHeight110};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize28};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight110};
      }
    `,
    body1: `
      font-family: ${vars.fonts.familyTertiary};
      font-size: ${vars.fonts.fontSize16};
      font-weight: ${vars.fonts.fontWeightRegular};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};\
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    body2: `
      font-family: ${vars.fonts.familyTertiary};
      font-size: ${vars.fonts.fontSize14};
      font-weight: ${vars.fonts.fontWeightRegular};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight130};
      }
    `,
    bodySmall: `
      font-family: ${vars.fonts.familyTertiary};
      font-size: ${vars.fonts.fontSize12};
      font-weight: ${vars.fonts.fontWeightRegular};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight130};
      }
    `,
    caption: `
      font-family: ${vars.fonts.familyTertiary};
      font-size: ${vars.fonts.fontSize12};
      font-weight: ${vars.fonts.fontWeightRegular};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
  });
};
export const buildThemeWidgetStylesUSOsw = (vars: Omit<Theme, 'widgets'>) => {
  const buildThemeWidget = buildThemeWidgetStylesUsLW(vars);

  return merge({}, buildThemeWidget, {
    GlobalHeader: {
      headerLogo: `
      img {
        max-width: 9em;

        @media (min-width: 451px) {
          max-width: 15.5em;
        }

        ${vars.breakpoints.lg} {
          max-width: 22.5em;
        }
      }`,
      stickymainNavWrapper: `
        ${buildThemeWidget.GlobalHeader.stickymainNavWrapper}
        .megaNavTab {
          ${vars.mixins.body1}
        }

        .topLevelLink .megaNavTab {
          font-size: 16px;
        }
      `,
      mainNavBarWrapper: `
        ${buildThemeWidget.GlobalHeader.mainNavBarWrapper}
        .megaNavTab {
          ${vars.mixins.h8}

          &:hover {
            text-decoration: none !important;
          }
          &:focus{
            text-decoration: none;
          }
        }
      `,
    },
    StateSelector: {
      default: `
      ${buildThemeWidget.StateSelector.default}
      .selector-title {
        font-family: ${vars.fonts.familyPrimary};
        font-size: 36px;
        line-height: 38px;
        font-weight: 400;
      }

      .selector-subtitle {
        font-family: ${vars.fonts.familyTertiary};
      }

      button.verify-state-btn {
        font-family: ${vars.fonts.familyTertiary} !important;
        border-radius: 30px !important;
        width: 100px;
        height: 34px;
        font-weight: 400;
        padding: 2.5px 22px;
      }
      `,
    },
    Search: {
      refinements: `
      ${buildThemeWidget.Search.refinements}
      .add-to-cart-btn-container {
        .cart-button {
          button {
            border-radius: 100px !important;
          }
        }
      }
      `,
      hitWrapper: `
      ${buildThemeWidget.Search.hitWrapper}
      &.grid-view {
        .ais-Hits-list {
          .ais-Hits-item {
            .hit-content {
              .web-description {
                color: ${vars.colors.grey50};
                line-height: 22.4px;
              }
              .inline-container {
                .reserveTodayContainer {
                  ${vars.breakpoints.xl} {
                    margin-top: 15px !important;
                  }
                  ${vars.breakpoints.xxl} {
                    margin-top: 10px !important;
                  }
                }
              }
              .presell-section {
                font-weight: ${vars.fonts.weightMediumPlus};
              }
            }
            .addToCart-container {
              .price-section-container {
                .price-section {
                  .sku-price {
                    .sales-price {
                      color: ${vars.colors.primary1};
                    }
                  }
                }
                .member-price-section {
                  line-height: 16px;
                }
                .savings-sales-price {
                  color: ${vars.colors.primary1};
                  line-height: 16px;
                }
              }
              .cart-slider {
                button {
                  border-radius: 30px;
                  font-weight: ${vars.fonts.weightMediumPlus};
                }
              }
              .add-to-cart-btn-container {
                .cart-button {
                  button {
                    border-radius: 30px;
                    font-weight: ${vars.fonts.weightMediumPlus};
                  }
                }
              }
            }
          }
        }
      }
      `,
      wrapper: `
      ${buildThemeWidget.Search.wrapper}
      .filter-header {
        p {
          font-weight: ${vars.fonts.weightBold};
        }
      }
      `,
    },
    Product: {
      wrapper: `
      ${buildThemeWidget.Product.wrapper}
      .product-layout {
        .unlimited-banner {
          button {
            border-radius: 20px !important;
          }
        }
      }
      `,
      orderform: `
      ${buildThemeWidget.Product.orderform}
      .add-to-cart-btn-container {
        .cart-button {
          button {
            border-radius: 100px !important;
          }
        }
      }
      `,
      learnMoreWrapper: `
      ${buildThemeWidget.Product.learnMoreWrapper}
      .mantine-Paper-root {
        border-radius: 12px;
      }
      .mantine-Modal-body {
        .modalTitle {
          font-family: ${vars.fonts.familyTertiary};
          line-height: 36px;
          margin-top: 22px !important;
        }
        .modalTitle2 {
          font-family: ${vars.fonts.familyTertiary};
        }
        .cart-button {
          button {
            border-radius: 30px;
          }
        }
      }
      `,
    },
    Footer: {
      default: `
      ${buildThemeWidget.Footer.default}
      font-family: ${vars.fonts.familySecondary};
      p {
        font-family: ${vars.fonts.familySecondary};
      }
      @media print {
        display: none;
      }
      .footer-bottom-section .wrapper {
        .copyrights * {
          font-family: ${vars.fonts.familySecondary};
        }
        ${vars.breakpoints.lg} {
          .bedrinkaware-group {
            justify-content: flex-start;
          }
        }
      }
      .footer-nav-links {
        background: ${vars.colors.secondary5};
        box-shadow: 0 0 0 100vmax ${vars.colors.secondary5};
        text-align: left;
      }
      .social-connections {
        .wrapper {
          .social-connection-heading p {
            font-family: ${vars.fonts.familySecondary};
            letter-spacing: 2px;
          }
        }
      }
      `,
      navItems: `
        ${buildThemeWidget.Footer.navItems}
        > div:first-of-type p{
          text-transform: uppercase;
          font-weight: bold;
        }
  `,
    },
    DrawerMenu: {
      drawerContainer: `
        ${buildThemeWidget.DrawerMenu.drawerContainer}

        .mantine-Drawer-body .drawer-main {
          background-color: ${vars.colors.secondary3};

          .navMenuBackLink {
            background-color: ${vars.colors.secondary3};
          }
        }

        .drawer-header-logo-section {
          ${vars.breakpoints.xs} {
            #header-logo a img {
              max-width: 13.5em;
            }
          }
        }
      `,
      mobileNavMenuContainer: `
      ${buildThemeWidget.DrawerMenu.mobileNavMenuContainer}
        .mantine-Drawer-body & .level1Link span:hover {
          color: ${vars.colors.primary1};
        }
        .mantine-Drawer-body & a.level1Link:hover {
          color: ${vars.colors.primary1};
        }
      `,
    },
    Cart: {
      MiniCart: `
      ${buildThemeWidget.Cart.MiniCart}

      .product-great-pick{
        font-weight: ${vars.fonts.weightSmall};
        font-family: ${vars.fonts.familyQuaternary};
        line-height: 23px;
        font-size: 18px;
        letter-spacing: 0.01em;
        ${vars.breakpoints.sm}{
          font-size: 22px;
          line-height: 28px;
        }
      }

      .product-link {
        font-weight: ${vars.fonts.weightSmall};
        line-height: 22.5px;
        font-size: 18px;
        ${vars.breakpoints.sm}{
          line-height: 28px;
          font-size: 22px;
        }
        font-family: ${vars.fonts.familyQuaternary};
        letter-spacing: 0.01em;
      }

      .product-detail-text .checkout-item-qty {
        font-family: ${vars.fonts.familyQuaternary};
      }

      .price-section {
        font-weight: ${vars.fonts.weightSmall};
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .price-section .price {
        font-weight: ${vars.fonts.weightSmall};
        font-family: ${vars.fonts.familyQuaternary};
      }
      .price-section .strike+.member-price {
        color:${vars.colors.accentBlue600};
      }

      .button-container button {
        border-radius: 25px;
        font-family: ${vars.fonts.familyTertiary};
      }

      .auc-Recommend .mini-cart-title, .product-item-container > .product-title, span.price-cart, .review-count {
        line-height: 22.5px !important;
        font-size: 18px !important;
        ${vars.breakpoints.sm}{
          line-height: 28px !important;
          font-size: 22px !important;
        }
        font-weight: ${vars.fonts.weightSmall} !important;
        font-family: ${vars.fonts.familyQuaternary} !important;
      }

      .product-item-container > .product-title {
        height:auto !important;
      }

      .product-rating .star-icons svg {
        width: 18px;
        height: 18px;
        ${vars.breakpoints.sm}{
          width: 22px;
          height: 22px;
        }
      }
      .continue-shopping .continue-shopping-text, .tax .tax-info, .num-bottles, .product-detail-cart  {
        font-family: ${vars.fonts.familyTertiary};
      }

      `,
      slider: `
      h2 {
        &.mantine-Drawer-title {
          font-family: ${vars.fonts.familyTertiary} !important;
          text-transform: capitalize !important;
          font-weight:${vars.fonts.weightBold} !important;
          line-height: 36px !important;
        }
      }
      .unlimited-banner {
          button {
            border-radius: 20px !important;
          }
      }
      ${buildThemeWidget.Cart.slider}
    `,
    },
  });
};

const createTheme: ThemeCreateFunction = () =>
  buildTheme(buildThemeVarsUSOsw, buildThemeMixinsUSOsw, buildThemeWidgetStylesUSOsw);

export default createTheme;
